/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
// eslint-disable-next-line import/no-webpack-loader-syntax
import imgLog from "../assets/common/logo_w.svg";
import React, { ReactElement, useEffect, useRef, useState } from "react";

import { useSpring, animated, config } from "react-spring";
import { Outlet } from "react-router-dom";
import "../assets/css/common.css";
import "../assets/css/demo1.css";
import "../assets/css/demo1_2.css";

import { WalletButton } from "./WalletButton";
import { WalletStatus } from "./WalletStatus";
import bgMain2 from "../assets/common/bg_main_2.png";
import bgMain from "../assets/common/bg_main.png";

interface CanvasItem extends HTMLCanvasElement {
  contextCache?: CanvasRenderingContext2D | null;
}

type Props = {
  children: JSX.Element;
};

export const Background: React.FC<Props> = ({ children }) => {
  let unit = 300;
  let canvasList: (CanvasItem | null)[] = []; // キャンバスの配列
  let info: {
    seconds: number;
    t: number;
  } = {
    seconds: 0,
    t: 0,
  }; // 全キャンバス共通の描画情報
  let colorList: string[][]; // 各キャンバスの色情報

  function init() {
    // 波の表現
    info.seconds = 0;
    info.t = 0;
    canvasList = [];
    colorList = [];
    // canvas1個めの色指定
    canvasList.push(
      document.getElementById("waveCanvas2") as HTMLCanvasElement
    );
    colorList.push(["#8e00ba", "#8e00ba", "#f00", "#00f", "#f0f"]); //重ねる波線の色設定

    canvasList.push(document.getElementById("waveCanvas") as HTMLCanvasElement);
    colorList.push(["#0ff", "#8e00ba", "#f00", "#00f", "#f0f"]); //重ねる波線の色設定

    // 各キャンバスの初期化
    for (let canvasIndex in canvasList) {
      const canvas = canvasList[canvasIndex];
      if (!canvas) return;
      canvas.width = document.documentElement.clientWidth; //Canvasのwidthをウィンドウの幅に合わせる
      canvas.height = 200; //波の高さ
      canvas.contextCache = canvas.getContext("2d");
    }
    // 共通の更新処理呼び出し
    update();
  }

  function update() {
    for (let canvasIndex in canvasList) {
      const canvas = canvasList[canvasIndex];
      // 各キャンバスの描画
      if (!canvas) return;
      draw(canvas, colorList[canvasIndex]);
    }
    // 共通の描画情報の更新
    info.seconds = info.seconds + 0.014;
    info.t = info.seconds * Math.PI;
    // 自身の再起呼び出し
    setTimeout(update, 5);
  }

  /**
   * Draw animation function.
   *
   * This function draws one frame of the animation, waits 20ms, and then calls
   * itself again.
   */
  function draw(canvas: CanvasItem, color: string[]) {
    // 対象のcanvasのコンテキストを取得
    const context = canvas.contextCache;
    // キャンバスの描画をクリア
    context?.clearRect(0, 0, canvas.width, canvas.height);

    //波線を描画 drawWave(canvas, color[数字（波の数を0から数えて指定）], 透過, 波の幅のzoom,波の開始位置の遅れ )
    drawWave(canvas, color[0], 0.8, 0.5, 0);
    drawWave(canvas, color[0], 0.7, 0.51, 0.01);
    drawWave(canvas, color[0], 0.6, 0.52, 0.02);
    drawWave(canvas, color[0], 0.5, 0.53, 0.03);
    drawWave(canvas, color[0], 0.4, 0.54, 0.04);
    drawWave(canvas, color[0], 0.3, 0.55, 0.05);
    drawWave(canvas, color[0], 0.2, 0.56, 0.06);
    drawWave(canvas, color[0], 0.1, 0.57, 0.07);
    drawWave(canvas, color[0], 0.2, 0.58, 0.08);
    drawWave(canvas, color[0], 0.3, 0.59, 0.09);
    drawWave(canvas, color[0], 0.4, 0.6, 0.1);
    drawWave(canvas, color[0], 0.5, 0.61, 0.11);
    drawWave(canvas, color[0], 0.6, 0.62, 0.12);
    drawWave(canvas, color[0], 0.7, 0.63, 0.13);
  }

  /**
   * 波を描画
   * drawWave(色, 不透明度, 波の幅のzoom, 波の開始位置の遅れ)
   */
  function drawWave(
    canvas: CanvasItem,
    color: string,
    alpha: number,
    zoom: number,
    delay: number
  ) {
    const context = canvas.contextCache;
    if (!context) return;
    context.strokeStyle = color; //線の色
    context.lineWidth = 1; //線の幅
    context.globalAlpha = alpha;
    context.beginPath(); //パスの開始
    drawSine(canvas, info.t / 18.8, zoom, delay);
    context.stroke(); //線
  }

  /**
   * Function to draw sine
   *
   * The sine curve is drawn in 10px segments starting at the origin.
   * drawSine(時間, 波の幅のzoom, 波の開始位置の遅れ)
   */
  function drawSine(
    canvas: CanvasItem,
    t: number,
    zoom: number,
    delay: number
  ) {
    let xAxis = Math.floor(canvas.height / 2);
    let yAxis = 0;
    const context = canvas.contextCache;
    // Set the initial x and y, starting at 0,0 and translating to the origin on
    // the canvas.
    if (!context) return;
    let x = t; //時間を横の位置とする
    let y = Math.sin(x) / zoom;
    context.moveTo(yAxis, unit * y + xAxis); //スタート位置にパスを置く

    // Loop to draw segments (横幅の分、波を描画)
    for (let i = yAxis; i <= canvas.width + 10; i += 10) {
      x = t + (-yAxis + i) / unit / zoom;
      y = Math.sin(x - delay) / 3;
      context.lineTo(i, unit * y + xAxis);
    }
  }
  useEffect(() => {
    init();
  }, []);

  // 背景フェード　価格帯の相談が完了後に実装

  const fadeStyle = css`
    position: absolute;
    overflow: hidden;
    background-size: contain;
    margin: 0px;
    border-width: 0px;
    border-style: none;
    border-color: rgb(0, 0, 0);
    opacity: 0.5;
  `;

  const [flip, set] = useState(false);
  let a = bgMain;
  let b = bgMain2;
  const c = useSpring({
    to: { opacity: 0.5 },
    from: { opacity: 0 },
    reset: true,
    reverse: flip,
    config: { duration: 3000 },
    onRest: () => {
      set(!flip);
      if (!flip) {
        a = bgMain;
        b = bgMain2;
      } else {
        a = bgMain2;
        b = bgMain;
      }
    },
  });

  return (
    <>
      <animated.img src={a} css={fadeStyle} />
      <animated.img src={b} css={fadeStyle} style={c} />
      <div className="wavecanvas wavecanvas_wl">
        <canvas id="waveCanvas"></canvas>
        <canvas id="waveCanvas2"></canvas>
      </div>
      <WalletStatus />
      <div className="wrapper wrapper_wl">
        <div className="bg-mainvisual">
          <section className="main-visual main-visual_wl">
            <div>
              <header className="main-visual_wl_header">
                <h1>
                  <img src={imgLog} alt="" />
                  <span>MINT</span>
                </h1>
              </header>
              <div className="main-visual_wl_body">{children}</div>
              <div className="main-visual_wl_footer">
                <p>Copyrights ©︎ 2022 SINE All rights reserved.</p>
              </div>
            </div>
          </section>
        </div>
        <div></div>
      </div>
    </>
  );
};
