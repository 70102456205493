import { DEFAULT_CHAIN_NETWORK_NAME } from "../blockchain";
import { TheNFTAddress as FantomtestTheNFTAddress } from "./fantomtest";
import { TheNFTAddress as FantomTheNFTAddress } from "./fantom";
import { TheNFTAddress as BSCtestTheNFTAddress } from "./bsctest";
import { TheNFTAddress as GoerliTheNFTAddress } from "./goerli";
import { TheNFTAddress as MainnetTheNFTAddress } from "./mainnet";

let networkname = DEFAULT_CHAIN_NETWORK_NAME;
let theaddress = "";

if (networkname == "fantomtest") {
  theaddress = FantomtestTheNFTAddress;
} else if (networkname == "fantom") {
  theaddress = FantomTheNFTAddress;
} else if (networkname == "bsctest") {
  theaddress = BSCtestTheNFTAddress;
} else if (networkname == "goerli") {
  theaddress = GoerliTheNFTAddress;
} else if (networkname == "mainnet") {
  theaddress = MainnetTheNFTAddress;
}

console.log("theaddress networkname: ", networkname);
console.log("theaddress: ", theaddress);
console.log("theaddress FantomTheNFTAddress: ", FantomTheNFTAddress);
console.log("theaddress FantomtestTheNFTAddress: ", FantomtestTheNFTAddress);
console.log("theaddress BSCtestTheNFTAddress: ", BSCtestTheNFTAddress);

export const TheNFTAddress = theaddress;
