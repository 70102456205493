function NotFound() {
  return (
    <div className="not-found-page">
      <p>
        Page
        <br /> not
        <br /> found
      </p>
    </div>
  );
}

export default NotFound;
