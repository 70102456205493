import { NETWORKS } from "../constants/blockchain";
import { DEFAULT_CHAIN_ID } from "../constants/blockchain";
const scannerURL = NETWORKS[DEFAULT_CHAIN_ID].blockExplorerUrls[0];

export const TransactionToastMessage = (msg: string, hash: string) => {
  const url = scannerURL + "/tx/" + hash;

  console.log("TransactionToastMessage.scannerURL: ", scannerURL);
  console.log("TransactionToastMessage.url: ", url);
  console.log("TransactionToastMessage.hash: ", hash);
  console.log("TransactionToastMessage.msg: ", msg);

  return (
    <div>
      <a target="_blank" href={url} rel="noreferrer">
        {msg} ({hash})
      </a>
    </div>
  );
};
