import { Networks, DEFAULT_CHAIN_ID } from "../constants/blockchain";

const switchRequestAvalancheFujiTest = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0xA869" }], //42113
  });
};

const switchRequestAstar = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x250" }], // 593
  });
};

const addChainRequestAstar = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x250", //593
        chainName: "Astar Network Mainnet",
        rpcUrls: ["https://rpc.astar.network:8545"],
        blockExplorerUrls: ["https://blockscout.com/astar"],
        nativeCurrency: {
          name: "ASTR",
          symbol: "ASTR",
          decimals: 18,
        },
      },
    ],
  });
};
const addChainRequestGoerli = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x5", //5
        chainName: "Goerli",
        rpcUrls: [
          "https://eth-goerli.g.alchemy.com/v2/ZRAKVYkEFJ_5GVrUv3u3DWmgudcR-vYp",
        ],
        blockExplorerUrls: ["https://goerli.etherscan.io/"],
        nativeCurrency: {
          name: "ETH",
          symbol: "ETH",
          decimals: 18,
        },
      },
    ],
  });
};

const switchRequestGoerli = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x5" }], // 5
  });
};
const switchRequestShibuya = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x51" }], // 81
  });
};
const addChainRequestShibuya = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x51", //81
        chainName: "Shibuya Network ",
        rpcUrls: ["https://rpc.shibuya.astar.network:8545"],
        blockExplorerUrls: ["https://blockscout.com/shibuya"],
        nativeCurrency: {
          name: "SBY",
          symbol: "SBY",
          decimals: 18,
        },
      },
    ],
  });
};

const switchRequestShiden = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x150" }], // 336
  });
};
const addChainRequestShiden = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x150", //336
        chainName: "Shiden",
        rpcUrls: ["https://rpc.shiden.astar.network:8545"],
        blockExplorerUrls: ["https://blockscout.com/shiden"],
        nativeCurrency: {
          name: "SDN",
          symbol: "SDN",
          decimals: 18,
        },
      },
    ],
  });
};

const addChainRequestAvalancheFujiTest = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0xA869", //42113
        chainName: "Avalanche Fuji Testnet",
        rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
        blockExplorerUrls: ["https://testnet.snowtrace.io/#/"],
        nativeCurrency: {
          name: "AVAX",
          symbol: "AVAX",
          decimals: 18,
        },
      },
    ],
  });
};
const switchRequestAvalanche = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0xa86a" }], // 43114
  });
};

const addChainRequestAvalanche = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0xa86a", //43114
        chainName: "Avalanche Mainnet",
        rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
        blockExplorerUrls: ["https://cchain.explorer.avax.network/"],
        nativeCurrency: {
          name: "AVAX",
          symbol: "AVAX",
          decimals: 18,
        },
      },
    ],
  });
};

const switchRequestBNBTest = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x61" }], //97
  });
};

const addChainRequestBNBTest = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x61", //97
        chainName: "BNBTest",
        rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        blockExplorerUrls: ["https://testnet.bscscan.com"],
        nativeCurrency: {
          name: "BNB",
          symbol: "BNB",
          decimals: 18,
        },
      },
    ],
  });
};
const switchRequestBNB = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x38" }], //56
  });
};

const addChainRequestBNB = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x38", //56
        chainName: "BNB",
        rpcUrls: ["hthttps://bsc-dataseed.binance.org"],
        blockExplorerUrls: ["https://bscscan.com"],
        nativeCurrency: {
          name: "BNB",
          symbol: "BNB",
          decimals: 18,
        },
      },
    ],
  });
};
const switchRequestFantom = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0xfa" }], //250
  });
};

const addChainRequestFantom = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0xfa", //250
        chainName: "Fantom",
        rpcUrls: ["https://rpc.ftm.tools"],
        blockExplorerUrls: ["https://ftmscan.com/"],
        nativeCurrency: {
          name: "FTM",
          symbol: "FTM",
          decimals: 18,
        },
      },
    ],
  });
};

const switchRequestFantomTest = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0xfa2" }],
  });
};

const addChainRequestFantomTest = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0xfa2",
        chainName: "Fantom Testnet",
        rpcUrls: ["https://rpc.testnet.fantom.network"],
        blockExplorerUrls: ["https://testnet.ftmscan.com/"],
        nativeCurrency: {
          name: "FTM(test)",
          symbol: "FTM",
          decimals: 18,
        },
      },
    ],
  });
};

const switchRequestPolygon = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x89" }], //137
  });
};

const addChainRequestPolygon = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x89", //137,
        chainName: "Fantom Testnet",
        rpcUrls: ["https://polygon-rpc.com"],
        blockExplorerUrls: ["https://polygonscan.com/"],
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
      },
    ],
  });
};

const switchRequestMainnet = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x1" }], // 1
  });
};

const switchRequestMumbai = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x13881" }], //80001
  });
};

const addChainRequestMumbai = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x13881", //80001
        chainName: "Polygon(Mumbai)",
        // rpcUrls: ["https://matic-testnet-archive-rpc.bwarelabs.com"],
        rpcUrls: [
          "https://polygon-mumbai.g.alchemy.com/v2/3AUyM1_NoaEnBfvDrQgUxAdLYCiklOYB",
        ],
        blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
        nativeCurrency: {
          name: "MATIC",
          symbol: "MATIC",
          decimals: 18,
        },
      },
    ],
  });
};

const switchRequestMoonriver = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x505" }], //1285
  });
};

const addChainRequestMoonriver = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x505", //1285
        chainName: "Moonriver",
        rpcUrls: ["https://rpc.moonriver.moonbeam.network"],
        blockExplorerUrls: ["https://moonriver.moonscan.io/"],
        nativeCurrency: {
          name: "MOVR",
          symbol: "MOVR",
          decimals: 18,
        },
      },
    ],
  });
};

const switchRequestMoonbeam = () => {
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: "0x504" }], //1284
  });
};

const addChainRequestMoonbeam = () => {
  return window.ethereum.request({
    method: "wallet_addEthereumChain",
    params: [
      {
        chainId: "0x504", //1284
        chainName: "Moonbeam",
        rpcUrls: ["https://rpc.api.moonbeam.network"],
        blockExplorerUrls: ["https://moonbase.moonscan.io/"],
        nativeCurrency: {
          name: "MOVR",
          symbol: "MOVR",
          decimals: 18,
        },
      },
    ],
  });
};

export const swithNetwork = async (chainId: number = DEFAULT_CHAIN_ID) => {
  if (window.ethereum) {
    try {
      if (chainId == 97) {
        await switchRequestBNBTest();
      } else if (chainId == 1) {
        await switchRequestMainnet();
      } else if (chainId == 5) {
        await switchRequestGoerli();
      } else if (chainId == 56) {
        await switchRequestBNB();
      } else if (chainId == 250) {
        await switchRequestFantom();
      } else if (chainId == 592) {
        await switchRequestAstar();
      } else if (chainId == 4002) {
        await switchRequestFantomTest();
      } else if (chainId == 81) {
        await switchRequestShibuya();
      } else if (chainId == 336) {
        await switchRequestShiden();
      } else if (chainId == 250) {
        await switchRequestFantom();
      } else if (chainId == 43113) {
        await switchRequestAvalancheFujiTest();
      } else if (chainId == 43114) {
        await switchRequestAvalanche();
      } else if (chainId == 137) {
        await switchRequestPolygon();
      } else if (chainId == 80001) {
        await switchRequestMumbai();
      } else if (chainId == 137) {
        await switchRequestPolygon();
      } else if (chainId == 1285) {
        await switchRequestMoonriver();
      } else if (chainId == 1284) {
        await switchRequestMoonbeam();
      }
      // await switchRequest();
    } catch (error: any) {
      if (error.code === 4902) {
        try {
          if (chainId == 97) {
            await addChainRequestBNBTest();
            await switchRequestBNBTest();
          } else if (chainId == 56) {
            await addChainRequestBNB();
            await switchRequestBNB();
          } else if (chainId == 5) {
            await addChainRequestGoerli();
            await switchRequestGoerli();
          } else if (chainId == 250) {
            await addChainRequestFantom();
            await switchRequestFantom();
          } else if (chainId == 592) {
            await addChainRequestAstar();
            await switchRequestAstar();
          } else if (chainId == 4002) {
            await addChainRequestFantomTest();
            await switchRequestFantomTest();
          } else if (chainId == 81) {
            await addChainRequestShibuya();
            await switchRequestShibuya();
          } else if (chainId == 336) {
            await addChainRequestShiden();
            await switchRequestShiden();
          } else if (chainId == 250) {
            await addChainRequestFantom();
            await switchRequestFantom();
          } else if (chainId == 43113) {
            await addChainRequestAvalancheFujiTest();
            await switchRequestAvalancheFujiTest();
          } else if (chainId == 43114) {
            await addChainRequestAvalanche();
            await switchRequestAvalanche();
          } else if (chainId == 137) {
            await addChainRequestPolygon();
            await switchRequestPolygon();
          } else if (chainId == 80001) {
            await addChainRequestMumbai();
            await switchRequestMumbai();
          } else if (chainId == 1285) {
            await addChainRequestMoonriver();
            await switchRequestMoonriver();
          } else if (chainId == 1284) {
            await addChainRequestMoonbeam();
            await switchRequestMoonbeam();
          }
        } catch (addError) {
          console.log(error);
        }
      }
      console.log(error);
    }
  }
};
