import { Networks } from "../constants/blockchain";
import { TheNFTAddress } from "../constants/addresses";
import {
  JsonRpcSigner,
  StaticJsonRpcProvider,
  JsonRpcProvider,
} from "@ethersproject/providers";
import { Contract } from "@ethersproject/contracts";

// import { abi as TheNFTAbi } from "../abi/TheNFT.json";
import TheNFT from "../abi/TheNFT.json";
import { NETWORKS, DEFAULT_CHAIN_ID } from "../constants/blockchain";
const TheNFTAbi = TheNFT.abi;

export const getNFTContract = (
  provider?: StaticJsonRpcProvider | JsonRpcSigner
) => {
  return getContract(TheNFTAddress, TheNFTAbi, provider);
};

export const simpleRpcProvider = new JsonRpcProvider(
  NETWORKS[DEFAULT_CHAIN_ID]["rpcUrls"][0]
);

const getContract = (
  address: string,
  abi: any,
  signer?: StaticJsonRpcProvider | JsonRpcSigner
) => {
  const signerOrProvider = signer ? signer : simpleRpcProvider;
  return new Contract(address, abi, signerOrProvider);
};
