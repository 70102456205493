import { Button, Box, Text } from "@chakra-ui/react";
import { useWeb3Context } from "../hooks";
import { trim, shorten } from "../helpers";
import { DEFAULT_NETWORK, USER_SELECTABLE_NETWORKS } from "../constants";
import { useCallback, useEffect, useState } from "react";
import { WalletButton } from "./WalletButton";

export const WalletStatus = () => {
  const {
    connect,
    disconnect,
    connected,
    web3,
    web3Modal,
    providerChainID,
    address,
    checkWrongNetwork,
    whitelist,
    whitelist2,
    hasCachedProvider,
  } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  const onConnect = useCallback(() => {
    connect().catch((e) => {
      //キャッチするとmetamaskのモーダルが出ます。
      console.log(e);
    });
  }, [connect]);

  let clickFunc: any = onConnect;

  // wallet buttonの出し分け
  let text = "";
  let style = "";
  const isOnWhiteList = whitelist || whitelist2; // whiteListかどうか
  console.log("ホワイトかどうか確認：", isOnWhiteList);
  if (isConnected && !USER_SELECTABLE_NETWORKS.includes(providerChainID)) {
    text = "WRONG NETWORK";
    style = "red";
    clickFunc = checkWrongNetwork;
  } else if (isConnected) {
    text = "CONNECTED";
    style = "btn-connect-over";
    clickFunc = disconnect;
  } else {
    text = "CONNECT WALLET";
    style = "btn-connect-wallet";
  }

  return (
    <>
      {/* {shorten(address)}
      <Box>
        <Button onClick={clickFunc} colorScheme={buttonStyle}>
          <Text> {buttonText}</Text>
        </zyouButton>
      </Box> */}
      <WalletButton
        onClickHandler={clickFunc}
        text={text}
        style={style}
        isConnected={connected}
        isWhiteListMenber={isOnWhiteList}
      />
    </>
  );
};
