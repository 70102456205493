import * as React from "react";

import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Home from "./views/Home";
import NotFound from "./views/NotFound";
import { useWeb3Context } from "./hooks";
import Layout from "./layout";
import OwnerMint from "./views/Mint/OwnerMint";
import FirstSale from "./views/Mint/FirstSale";
import SecondSale from "./views/Mint/SecondSale";
import ThirdSale from "./views/Mint/ThirdSale";

import Admin from "./views/Admin";
import AdminFirst from "./views/AdminFirst";
import { useEffect, useState, useCallback } from "react";
import AdminSecond from "./views/AdminSecond";
import AdminThird from "./views/AdminThird";
import AdminGeneral from "./views/AdminGeneral";
import { Background } from "./components/Background";
import SecondSaleWL from "./views/Mint/SecondSaleWL";
import SecondSaleSP from "./views/Mint/SecondSaleSP";

// 早期MINT用(WL)
const propsWLEarly = {
  serviceStart: 1680001200, // MINT開始：2023/3/28 20:00~(日本時間)
  multiMintDate: 1680001200, // 複数MINT対応開始：2023/3/28 20:00~(日本時間)
  secondSaleLimit: 5000, // 3000スタートで8000まで(販売数5000MINT)
  secondSaleMintLimitWL: 50, // WLは10Mint
  secondSaleMintLimitSP: 5, // SPは5Mint
};

// 早期MINT用(SP)
const propsSPEarly = {
  serviceStart: 1680004800, // MINT開始：2023/3/28 21:00~(日本時間)
  multiMintDate: 1680004800, // 複数MINT対応開始：2023/3/28 21:00~(日本時間)
  secondSaleLimit: 5000, // 3000スタートで8000まで(販売数5000MINT)
  secondSaleMintLimitWL: 50, // WLは10Mint
  secondSaleMintLimitSP: 5, // SPは5Mint
};

// MINT用(WL)
const propsWL = {
  serviceStart: 1680004800, // MINT開始：2023/3/28 21:00~(日本時間)
  multiMintDate: 1680006600, // 複数MINT対応開始：2023/3/28 21:30~(日本時間)
  secondSaleLimit: 5000, // 3000スタートで8000まで(販売数5000MINT)
  secondSaleMintLimitWL: 50, // WLは10Mint
  secondSaleMintLimitSP: 5, // SPは5Mint
};

// MINT用(SP)
const propsSP = {
  serviceStart: 1680006600, // MINT開始：2023/3/28 21:30~(日本時間)
  multiMintDate: 1680006600, // 複数MINT対応開始：2023/3/28 21:30~(日本時間)
  secondSaleLimit: 5000, // 3000スタートで8000まで(販売数5000MINT)
  secondSaleMintLimitWL: 50, // WLは10Mint
  secondSaleMintLimitSP: 5, // SPは5Mint
};

export const App = () => {
  const { connect, provider, hasCachedProvider, chainID, connected } =
    useWeb3Context();
  const [walletChecked, setWalletChecked] = useState(false);

  useEffect(() => {
    if (hasCachedProvider()) {
      connect().then(() => {
        setWalletChecked(true);
      });
    } else {
      // setWalletChecked(true);
      setWalletChecked(false);
    }
  }, []);
  return (
    <>
      <ToastContainer />
      <Background>
        <Routes>
          <Route
            path="second/7f8cb125d67caf262c4b0cf51dc17bfdfd568303779670762d6900c22a86c81a"
            element={
              <SecondSaleWL
                serviceStart={propsWLEarly.serviceStart}
                multiMintDate={propsWLEarly.multiMintDate}
                secondSaleLimit={propsWLEarly.secondSaleLimit}
                secondSaleMintLimitWL={propsWLEarly.secondSaleMintLimitWL}
                secondSaleMintLimitSP={propsWLEarly.secondSaleMintLimitSP}
              />
            }
          />
          <Route
            path="second/fac02d029b4d19284d2885d8be9bb01f0c4f44c8b3e8e3d1341bb06e7c48f16d"
            element={
              <SecondSaleSP
                serviceStart={propsSPEarly.serviceStart}
                multiMintDate={propsSPEarly.multiMintDate}
                secondSaleLimit={propsSPEarly.secondSaleLimit}
                secondSaleMintLimitWL={propsSPEarly.secondSaleMintLimitWL}
                secondSaleMintLimitSP={propsSPEarly.secondSaleMintLimitSP}
              />
            }
          />
          <Route
            path="second/wl"
            element={
              <SecondSaleWL
                serviceStart={propsWL.serviceStart}
                multiMintDate={propsWL.multiMintDate}
                secondSaleLimit={propsWL.secondSaleLimit}
                secondSaleMintLimitWL={propsWL.secondSaleMintLimitWL}
                secondSaleMintLimitSP={propsWL.secondSaleMintLimitSP}
              />
            }
          />
          <Route
            path="second/sp"
            element={
              <SecondSaleSP
                serviceStart={propsSP.serviceStart}
                multiMintDate={propsSP.multiMintDate}
                secondSaleLimit={propsSP.secondSaleLimit}
                secondSaleMintLimitWL={propsSP.secondSaleMintLimitWL}
                secondSaleMintLimitSP={propsSP.secondSaleMintLimitSP}
              />
            }
          />
          <Route path="*" element={<NotFound />} />
          {/* <Route path="*" element={<FirstSale />} /> */}
        </Routes>
      </Background>

      {/* {walletChecked ? ( */}
      {/* <>
        <ToastContainer />
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/first" element={<AdminFirst />} />
            <Route path="/admin/second" element={<AdminSecond />} />
            <Route path="/admin/third" element={<AdminThird />} />
            <Route path="/mint" element={<Background />}>
              <Route path="first" element={<FirstSale />} />
              <Route path="owner" element={<OwnerMint />} />
              <Route path="second" element={<SecondSale />} />
              <Route path="third" element={<ThirdSale />} />
            </Route>
            <Route path="/admin/general" element={<AdminGeneral />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </Layout>
      </> */}
      {/* ) : (
        <>loading</>
      )} */}
    </>
  );
};

export default App;
