/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Box,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import * as React from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { TheNFTAddress } from "../../constants/addresses";
import { getNFTContract } from "../../helpers/contracts";
import { useWeb3Context } from "../../hooks";

import { useEffect, useMemo, useState } from "react";
import {
  DEFAULT_NETWORK,
  NETWORKS,
  DEFAULT_CHAIN_ID,
  USER_SELECTABLE_NETWORKS,
} from "../../constants";
import { Button } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { secondSaleMint, thirdSaleMint } from "../../store/slices/mint-slice";
import { useFormik } from "formik";
import { IReduxState } from "../../store/slices/state.interface";
import { BigNumber } from "ethers";
import Loading from "../../components/Loading";
import { TransactionToastMessage } from "../../components/TransactionToastMessage";
import { toast } from "react-toastify";

const displayToast = async (msg: string, hash: string) => {
  toast(TransactionToastMessage(msg, hash));
};
const scannerURL = NETWORKS[DEFAULT_CHAIN_ID].blockExplorerUrls[0];

type props = {
  serviceStart: number;
  multiMintDate: number;
  secondSaleLimit: number;
  secondSaleMintLimitWL: number;
  secondSaleMintLimitSP: number;
};

export const SecondSaleWL = (props: props) => {
  const serviceStart = props.serviceStart;
  const multiMintDate = props.multiMintDate;
  const now = Math.floor(Date.now() / 1000);
  const SECOND_SALE_LIMIT = props.secondSaleLimit;
  const SECOND_SALE_MINT_LIMIT_WL = props.secondSaleMintLimitWL;
  const SECOND_SALE_MINT_LIMIT_SP = props.secondSaleMintLimitSP;

  const navigate = useNavigate();

  const {
    connect,
    disconnect,
    connected,
    web3,
    providerChainID,
    chainID,
    address,
    provider,
    checkWrongNetwork,
    setWhitelist,
    setWhitelist2,
  } = useWeb3Context();
  const [isConnected, setConnected] = useState(connected);
  const mintLoading = useSelector<IReduxState, boolean>(
    (state) => state.mint.loading
  );
  const mintResult = useSelector<IReduxState, boolean>(
    (state) => state.mint.result
  );

  const dispatch = useDispatch();

  const [merkle, setMerkle] = useState([]);
  const [whitelist, setWhitelistLocal] = useState(false);
  const [whitelist2, setWhitelist2Local] = useState(false);
  const [hexProof, setHexProof] = useState([]);
  const [hexProof2, setHexProof2] = useState([]);
  const [totalSupply, setTotalSupply] = useState("");
  const [isMintSuccessful, setIsMintSuccessful] = useState<boolean>(); // MINTが成功下かどうか(MINTを実行した時はundefine)
  const isWrongNetwork = useMemo(
    () => isConnected && !USER_SELECTABLE_NETWORKS.includes(providerChainID),
    [isConnected, providerChainID, USER_SELECTABLE_NETWORKS]
  );

  const getMerkleData = (addr: string) => {
    fetch("https://metacamelot.sxg.workers.dev/address/" + addr)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setHexProof(result.hexProof);
          setHexProof2(result.hexProof2);
          setWhitelistLocal(result.whitelist);
          setWhitelist2Local(result.whitelist2);
          setWhitelist(result.whitelist);
          setWhitelist2(result.whitelist2);
          setMerkle(result);
        },
        (error) => {
          console.log(error);
        }
      )
      .catch((error) => {
        console.error("failed to connect ", error);
      });
  };
  const isOnWhiteList = whitelist || whitelist2; // whiteListかどうか
  console.log("ホワイトかどうか確認：", isOnWhiteList);

  const [secondMinted, setSecondMinted] = useState("");
  const [totalMinted, setTotalMinted] = useState("");
  const [totalMintLimit, setTotalMintLimit] = useState("");
  const [secondSaleLimit, setSecondSaleLimit] = useState("");
  const [secondSaleStart, setSecondSaleStart] = useState(false);
  const [secondSaleMintLimit, setSecondSaleMintLimit] = useState("");
  const [secondSaleMintPrice, setSecondSaleMintPrice] = useState("");
  const [secondSaleMintPriceSP, setSecondSaleMintPriceSP] = useState("");
  const [secondSaleMintPriceWL, setSecondSaleMintPriceWL] = useState("");
  const [thirdMinted, setThirdMinted] = useState("");
  const [thirdSaleLimit, setThirdSaleLimit] = useState("");
  const [thirdSaleStart, setThirdSaleStart] = useState(false);
  const [thirdSaleMintLimit, setThirdSaleMintLimit] = useState("");
  const [thirdSaleMintPrice, setThirdSaleMintPrice] = useState("");
  const [thirdSaleMintPriceSP, setThirdSaleMintPriceSP] = useState("");
  const [thirdSaleMintPriceWL, setThirdSaleMintPriceWL] = useState("");

  useEffect(() => {
    const con = getNFTContract(provider.getSigner());
    con.totalSupply().then((totalSupply_: BigNumber) => {
      const a = totalSupply_.toNumber() + 20;
      console.log("totalSupply: ", totalSupply_);
      setTotalSupply(a.toString());
    });
    if (address) {
      // 共通
      con.totalMinted(address).then((num: number) => {
        setTotalMinted(num.toString());
      });
      con.totalMintLimit().then((num: number) => {
        setTotalMintLimit(num.toString());
      });

      // SecondMint関連
      con.secondMinted(address).then((num: number) => {
        setSecondMinted(num.toString());
      });
      con.secondSaleLimit().then((num: number) => {
        setSecondSaleLimit(num.toString());
      });
      con.secondSaleStart().then((num: boolean) => {
        setSecondSaleStart(num);
      });
      con.secondSaleMintLimit().then((num: number) => {
        setSecondSaleMintLimit(num.toString());
      });
      con.secondSaleMintPrice().then((num: BigNumber) => {
        setSecondSaleMintPrice(
          (
            Number.parseInt(
              num.div(BigNumber.from("1000000000000")).toString()
            ) / 1000000
          ).toString()
        );
      });
      con.secondSaleMintPriceWL().then((num: BigNumber) => {
        setSecondSaleMintPriceWL(
          (
            Number.parseInt(
              num.div(BigNumber.from("1000000000000")).toString()
            ) / 1000000
          ).toString()
        );
      });
      con.secondSaleMintPriceSP().then((num: BigNumber) => {
        setSecondSaleMintPriceSP(
          (
            Number.parseInt(
              num.div(BigNumber.from("1000000000000")).toString()
            ) / 1000000
          ).toString()
        );
      });
    }

    // ThirdMint関連
    con.thirdMinted(address).then((num: number) => {
      setThirdMinted(num.toString());
    });
    con.thirdSaleLimit().then((num: number) => {
      setThirdSaleLimit(num.toString());
    });
    con.thirdSaleStart().then((num: boolean) => {
      setThirdSaleStart(num);
    });
    con.thirdSaleMintLimit().then((num: number) => {
      setThirdSaleMintLimit(num.toString());
    });
    con.thirdSaleMintPrice().then((num: BigNumber) => {
      setThirdSaleMintPrice(
        (
          Number.parseInt(num.div(BigNumber.from("1000000000000")).toString()) /
          1000000
        ).toString()
      );
    });

    con.thirdSaleMintPriceWL().then((num: BigNumber) => {
      setThirdSaleMintPriceWL(
        (
          Number.parseInt(num.div(BigNumber.from("1000000000000")).toString()) /
          1000000
        ).toString()
      );
    });

    con.thirdSaleMintPriceSP().then((num: BigNumber) => {
      setThirdSaleMintPriceSP(
        (
          Number.parseInt(num.div(BigNumber.from("1000000000000")).toString()) /
          1000000
        ).toString()
      );
    });
  }, [mintLoading, isConnected, mintResult]);

  useEffect(() => {
    if (address) {
      console.log("address:", address);
      getMerkleData(address);
    }
  }, [address]);

  // // 元のfoam処理
  // const formikSecondMint = useFormik({
  //   initialValues: {
  //     mintQuantity: "1",
  //     // description: "",
  //     // symbol: "",
  //     // title: "",
  //   },

  //   onSubmit: (values) => {
  //     console.log("formikSecondMint.onSubmit");
  //     console.log("mintQuantity: ", values.mintQuantity);
  //     const quantity = parseInt(values.mintQuantity);
  //     dispatch(
  //       //@ts-ignore
  //       secondSaleMint({
  //         account: address,
  //         quantity,
  //         hexProof1: hexProof,
  //         hexProof2: hexProof2,
  //         whitelist,
  //         whitelist2,
  //         networkID: chainID,
  //         provider,
  //       })
  //     );
  //   },
  // });

  // デザイン適用後のMINT処理
  const [mintNumber, setMintNumber] = useState<number>(1);
  const onSubmit = async () => {
    console.log("formikSecondSaleMint.onSubmit");
    console.log("mintQuantity: ", mintNumber);
    const quantity = mintNumber;
    try {
      // console.log("merkle: ", merkle.hexProof[0]);
      // const hexProof = merkle.hexProof[0];
      console.log("merkle: ", merkle);

      if (Number(totalSupply) < Number(secondSaleLimit)) {
        if (Number(totalSupply) + quantity <= Number(secondSaleLimit)) {
          // secondMint only
          console.log("secondMint only");
          await dispatch(
            //@ts-ignore
            secondSaleMint({
              account: address,
              quantity,
              hexProof1: hexProof,
              hexProof2: hexProof2,
              whitelist,
              whitelist2,
              networkID: chainID,
              provider,
            })
          );
        } else if (Number(totalSupply) + quantity > Number(secondSaleLimit)) {
          // secondMint + thirdMint
          console.log("secondMint + thirdMint");
          const forSecondMint: number =
            Number(secondSaleLimit) - Number(totalSupply);
          const forThirdMint: number = quantity - forSecondMint;
          if (
            forSecondMint < quantity &&
            forSecondMint >= 1 &&
            forThirdMint < quantity &&
            forThirdMint >= 1
          ) {
            await dispatch(
              //@ts-ignore
              secondSaleMint({
                account: address,
                quantity: forSecondMint,
                hexProof1: hexProof,
                hexProof2: hexProof2,
                whitelist,
                whitelist2,
                networkID: chainID,
                provider,
              })
            );
            await dispatch(
              //@ts-ignore
              thirdSaleMint({
                account: address,
                quantity: forThirdMint,
                hexProof1: hexProof,
                hexProof2: hexProof2,
                whitelist,
                whitelist2,
                networkID: chainID,
                provider,
              })
            );
          }
        }
      } else if (
        Number(totalSupply) >= Number(secondSaleLimit) &&
        Number(totalSupply) + quantity <= SECOND_SALE_LIMIT
      ) {
        // thirdMint only
        console.log("thirdMint only");
        await dispatch(
          //@ts-ignore
          thirdSaleMint({
            account: address,
            quantity,
            hexProof1: hexProof,
            hexProof2: hexProof2,
            whitelist,
            whitelist2,
            networkID: chainID,
            provider,
          })
        );
      }
      setIsMintSuccessful(true);
    } catch (e: any) {
      console.log(e);
      // toast(e?.reason);
      setIsMintSuccessful(false);
    }
  };

  // useEffect(() => {
  //   // console.log("newSymbol::::::", newSymbol);
  //   // console.log("newAddress::::::", newAddress);
  //   if (newSymbol != "") {
  //     dispatch(clearNewCreatedMint());
  //     navigate("/projects/" + newSymbol);
  //   }
  // }, [newAddress, newSymbol]);
  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  // if (!isConnected) {
  //   return (
  //     <>
  //       <Button onClick={connect} colorScheme="blue">
  //         connect
  //       </Button>
  //     </>
  //   );
  // }
  // if (isConnected && !USER_SELECTABLE_NETWORKS.includes(providerChainID)) {
  //   return (
  //     <>
  //       <Button onClick={checkWrongNetwork} colorScheme="red">
  //         switch network
  //       </Button>
  //     </>
  //   );
  // }

  const { mintStatusMessage, isValidClassName } = useMemo(() => {
    // MINT未実行、成功、失敗時の制御
    let mintStatusMessage = "";
    let isValidClassName = "";
    // MINT未実行の時は空で返す
    if (typeof isMintSuccessful === "undefined") {
      return {
        mintStatusMessage,
        isValidClassName,
      };
    }

    if (mintLoading) {
      return {
        mintStatusMessage,
        isValidClassName,
      };
    }

    if (isMintSuccessful && mintResult) {
      // MINT成功時
      mintStatusMessage = "MINT COMPLETE！";
      isValidClassName = "main-visual_wl_vailed display-block";
    } else {
      // MINT失敗時
      mintStatusMessage = "MINT FAILED！";
      isValidClassName = "main-visual_wl_invailed display-block";
    }
    return {
      mintStatusMessage,
      isValidClassName,
    };
  }, [isMintSuccessful, mintResult, mintLoading]);

  // スタイリング
  const titleStyle = css`
    color: #fff;
    font-size: 40rem;
    letter-spacing: 14rem;
    font-family: "Oswald", sans-serif;
    text-align: center;
    padding-bottom: 24rem;
  `;
  const mintNumButtonStyle = css`
    max-width: 80rem;
    width: 30%;
    border: none;
    background: linear-gradient(
      261deg,
      rgb(23, 152, 194) 0%,
      rgb(34, 208, 199) 100%
    );
    color: #fff;
    padding: 11px;
    font-size: 16rem;
    border-radius: 40rem;
  `;
  const mintNumStyle = css`
    color: #fff;
    font-size: 32rem;
    letter-spacing: 14rem;
    font-family: "Oswald", sans-serif;
    text-align: center;
  `;
  const mintNumWrapper = css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30rem;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  `;

  return (
    <>
      {/* ここからデザインa */}

      <div css={titleStyle}>White List</div>

      <div className="l-data-number">
        <dl>
          <dt>YOUR ENTRIES</dt>
          <dd>
            {Number(secondMinted) + Number(thirdMinted)} /{" "}
            {SECOND_SALE_MINT_LIMIT_WL} {/* secondSaleMintLimit */}
          </dd>
        </dl>
        <dl>
          <dt>TOTAL ENTRIES</dt>
          <dd>
            {totalSupply} / {SECOND_SALE_LIMIT} {/* secondSaleLimit */}
          </dd>
        </dl>
      </div>

      {/* 確認用 */}
      {/* <div className="main-visual_wl_result">
        <p className={"wh-read-text_failed display-block"}>
          disable ={" "}
          {(
            !secondSaleStart ||
            !thirdSaleStart ||
            !whitelist ||
            whitelist2 ||
            isWrongNetwork ||
            (now < multiMintDate && parseInt(secondMinted) >= 1) ||
            Number(totalSupply) <= SECOND_SALE_LIMIT ||
            SECOND_SALE_MINT_LIMIT_WL <=
              Number(secondMinted) + Number(thirdMinted)
          ).toString()}
          <br />
          !secondSaleStart = {(!secondSaleStart).toString()}
          <br />
          !thirdSaleStart = {(!thirdSaleStart).toString()}
          <br />
          !whitelist = {(!whitelist).toString()}
          <br />
          whitelist2 = {whitelist2.toString()}
          <br />
          isWrongNetwork = {isWrongNetwork.toString()}
          <br />
          isWrongNetwork = {isWrongNetwork.toString()}
          <br />
          maruti ={" "}
          {(now < multiMintDate && parseInt(secondMinted) >= 1).toString()}
          <br />
          セカンドセールが終わっていないか ={" "}
          {(Number(totalSupply) >= SECOND_SALE_LIMIT).toString()}
          <br />
          {Number(totalSupply)}
          <br />
          {SECOND_SALE_LIMIT}
          <br />
          個人のMINTが終わっていないか ={" "}
          {(
            SECOND_SALE_MINT_LIMIT_WL <=
            Number(secondMinted) + Number(thirdMinted)
          ).toString()}
        </p>
      </div> */}

      <div className="main-visual_wl_result">
        <span className={isValidClassName}>{mintStatusMessage}</span>
      </div>
      {isMintSuccessful === false && (
        <p className={"wh-read-text_failed display-block"}>
          Something went wrong...
        </p>
      )}

      {/* WALLET未接続時 */}
      {!isConnected && (
        <p className="wh-read-text display-block">
          WALLETに接続されていません。
          <br />
          右上の「CONNECT WALLET」ボタンからWALLETに接続してください。
        </p>
      )}

      {/* WLではない場合 */}
      {isConnected && !whitelist && (
        <p className="wh-read-text display-block">
          WHITE LISTにアドレスがない為
          <br />
          MINTを実行できません。
        </p>
      )}
      {/* SPの場合 */}
      {isConnected && whitelist2 && (
        <>
          <p className="wh-read-text display-block">
            SPECIAL WHITE LISTの方は、下のページからMINTを実行してください。
          </p>
          <div css={mintNumWrapper}>
            <a className="main-visual_wl_btn" href="/second/sp">
              SPECIAL WHITE LIST PAGE
            </a>
          </div>
        </>
      )}

      {/* 販売開始していない場合 */}
      {now < serviceStart && (
        <>
          <p className="wh-read-text_caution display-block">
            サービス開始は{new Date(serviceStart * 1000).getMonth() + 1}月
            {new Date(serviceStart * 1000).getDate()}日
            {new Date(serviceStart * 1000).getHours()}:
            {("00" + new Date(serviceStart * 1000).getMinutes()).slice(-2)}
            ～です。
          </p>
        </>
      )}

      {/* 販売終了、購入済みの表示 */}
      {(Number(secondMinted) + Number(thirdMinted) >=
        SECOND_SALE_MINT_LIMIT_WL ||
        Number(totalSupply) >= SECOND_SALE_LIMIT) && (
        <>
          <p className="wh-read-text_caution display-block">
            ご購入いただき、ありがとうございました。
          </p>
        </>
      )}

      {/* MINT実行部分 */}
      {isConnected &&
        whitelist &&
        now >= serviceStart &&
        Number(secondMinted) + Number(thirdMinted) <
          SECOND_SALE_MINT_LIMIT_WL &&
        Number(totalSupply) < SECOND_SALE_LIMIT && (
          <>
            {now < multiMintDate ? (
              <>
                {/* マルチMINT開始前 */}
                <p className="wh-read-text_caution display-block">
                  <br />
                  {new Date(serviceStart * 1000).getMonth() + 1}月
                  {new Date(serviceStart * 1000).getDate()}日
                  {new Date(serviceStart * 1000).getHours()}:
                  {("00" + new Date(serviceStart * 1000).getMinutes()).slice(
                    -2
                  )}
                  ～{new Date(multiMintDate * 1000).getHours()}:
                  {("00" + new Date(multiMintDate * 1000).getMinutes()).slice(
                    -2
                  )}
                  までは1MINTしかできません。
                  <br />
                  2MINT以降は{new Date(multiMintDate * 1000).getMonth() + 1}月
                  {new Date(multiMintDate * 1000).getDate()}日
                  {new Date(multiMintDate * 1000).getHours()}:
                  {("00" + new Date(multiMintDate * 1000).getMinutes()).slice(
                    -2
                  )}
                  を過ぎてからお願いします。
                </p>
              </>
            ) : (
              <>
                {/* マルチMINT開始後 */}
                <div css={mintNumWrapper}>
                  <button
                    onClick={() => {
                      if (mintNumber > 1) {
                        setMintNumber((prev) => prev - 1);
                      }
                    }}
                    css={mintNumButtonStyle}
                  >
                    <ArrowLeftIcon />
                  </button>
                  <div css={mintNumStyle}>{"　　" + mintNumber + "　　"}</div>
                  <button
                    onClick={() => {
                      if (
                        mintNumber <
                        SECOND_SALE_MINT_LIMIT_WL -
                          (Number(secondMinted) + Number(thirdMinted))
                      ) {
                        setMintNumber((prev) => prev + 1);
                      }
                    }}
                    css={mintNumButtonStyle}
                  >
                    <ArrowRightIcon />
                  </button>
                </div>
              </>
            )}
            {/* MINTボタン */}
            <div css={mintNumWrapper}>
              <Loading isLoading={mintLoading}>
                <button
                  className={
                    connected &&
                    whitelist &&
                    !isWrongNetwork &&
                    !(now < multiMintDate && parseInt(secondMinted) >= 1) &&
                    secondSaleStart
                      ? "main-visual_wl_btn"
                      : "main-visual_wl_btn main-visual_wl_btn_stop"
                  }
                  disabled={
                    !secondSaleStart ||
                    !thirdSaleStart ||
                    !whitelist ||
                    isWrongNetwork ||
                    (now < multiMintDate && parseInt(secondMinted) >= 1) ||
                    Number(totalSupply) >= SECOND_SALE_LIMIT ||
                    SECOND_SALE_MINT_LIMIT_WL <=
                      Number(secondMinted) + Number(thirdMinted)
                  }
                  onClick={onSubmit}
                >
                  MINT
                </button>
              </Loading>
            </div>
          </>
        )}

      <p className="wh-read-text_caution display-block">
        2ndセール終了後、銀行振込分1000mintを行います
        <br />
        推奨環境、PCもしくはスマホアプリ「metamask」
        <br />
        ※スマホから上手く出来なかった場合は、PCからmintをしてみてください
      </p>

      {/* デザインここまで */}

      {/* ↓デバック用 */}
      {/* {whitelist ? (
        <>ホワイトリスト: 対象 </>
      ) : (
        <>ホワイトリスト: 対象ではありません</>
      )}
      <br />
      {whitelist2 ? (
        <>スペシャルホワイトリスト: 対象 </>
      ) : (
        <>スペシャルホワイトリスト: 対象ではありません</>
      )}
      <br />
      このアドレスがミントした(owner mint除く）すべてのラウンドでの数：
      {totalMinted}
      <br />
      価格： {secondSaleMintPrice}
      <br />
      価格(WL)： {secondSaleMintPriceWL}
      <br />
      価格(SP)： {secondSaleMintPriceSP}
      <br />
      これまでのNFT発行数：{totalSupply}
      <br />
      セカンドセールまでの制限：{secondSaleLimit}
      <br />
      ユーザあたりのすべてのラウンドでのミント数制限：{totalMintLimit}
      <br />
      このアドレスが セカンドセール でミントした数：{secondMinted}
      <br />
      WL/SPアドレス当たりのセカンドセール制限（ WL/SPでない場合 無関係 ）：
      {secondSaleMintLimit}
      <br />
      セール状態：{" "}
      {secondSaleStart ? <>セール開催中</> : <>セール未開催/終了/停止中</>}
      <br />
      <form onSubmit={formikSecondMint.handleSubmit}>
        <NumberInput defaultValue={1} min={1}>
          <NumberInputField
            name="mintQuantity"
            onChange={formikSecondMint.handleChange}
            value={formikSecondMint.values.mintQuantity}
          />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Button
          mt={4}
          colorScheme="teal"
          isLoading={mintLoading}
          type="submit"
          disabled={!secondSaleStart}
        >
          Second Sale Mint
        </Button>
      </form>
      <br />
      <hr />
      <br />
      <br />
      <hr />
      <br />
      {scannerURL}/address/{TheNFTAddress}
      <hr /> */}
    </>
  );
};
export default SecondSaleWL;
