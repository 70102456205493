/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";

type Props = {
  children: JSX.Element;
  isLoading: boolean;
};

const Loading: React.FC<Props> = ({ children, isLoading }) => {
  const selector = useSelector((state) => state);

  if (isLoading) {
    return (
      <ReactLoading
        type="bubbles"
        color="rgb(34, 208, 199)"
        height="100px"
        width="100px"
        className="mx-auto"
      />
    );
  } else {
    return <>{children}</>;
  }
};

export default Loading;

// スタイリング
const mintNumButtonStyle = css`
  max-width: 420rem;
  width: 90%;
  border: none;
  background: #979797;
  color: #fff;
  padding: 22px;
  font-size: 24rem;
  border-radius: 40rem;
`;
