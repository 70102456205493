export enum Networks {
  MUMBAI = 80001,
  ASTAR = 592,
  GOERLI = 5,
  SHIBUYA = 81,
  SHIDEN = 336,
  FANTOM_TESTNET = 4002,
  FANTOM = 250,
  POLYGON = 137,
  BNBTEST = 97,
  BNB = 56,
  FUJI = 43113,
  AVALANCHE = 43114,
  MAINNET = 1,
}
export const SCAN_URLS = {
  // [Networks.FANTOM_TESTNET]: "https://testnet.ftmscan.com/",
  // [Networks.FANTOM]: "https://ftmscan.com/",

  [Networks.GOERLI]: "https://goerli.etherscan.io/",
  [Networks.ASTAR]: "https://blockscout.com/astar",
  [Networks.SHIBUYA]: "https://blockscout.com/shibuya",
  [Networks.SHIDEN]: "https://blockscout.com/shiden",
  [Networks.MUMBAI]: "https://mumbai.polygonscan.com",
  [Networks.POLYGON]: "https://polygonscan.com",
  [Networks.FANTOM_TESTNET]: "https://testnet.ftmscan.com",
  [Networks.FANTOM]: "https://ftmscan.com/",
  [Networks.BNB]: "https://bscscan.com/",
  [Networks.BNBTEST]: "https://testnet.bscscan.com/",
  [Networks.AVALANCHE]: "https://api.avax.network/ext/bc/C/rpc",
  [Networks.FUJI]: "https://api.avax-test.network/ext/bc/C/rpc",
  [Networks.MAINNET]: "https://etherscan.io/",
};

// export const DEFAULT_CHAIN_NAME = "BNB TEST";
// export const DEFAULT_NETWORK = Networks.BNBTEST;
// export const DEFAULT_CHAIN_ID = 97;

// // mumbai
// export const DEFAULT_CHAIN_NAME = "Mumbai";
// export const DEFAULT_CHAIN_NETWORK_NAME = "mumbai";
// export const DEFAULT_NETWORK = Networks.MUMBAI;
// export const DEFAULT_CHAIN_ID = Networks.MUMBAI;
// export const USER_SELECTABLE_NETWORKS = [DEFAULT_CHAIN_ID];
// fantom test
// export const DEFAULT_CHAIN_NAME = "FantomTest";
// export const DEFAULT_CHAIN_NETWORK_NAME = "fantomtest";
// export const DEFAULT_NETWORK = Networks.FANTOM_TESTNET;
// export const DEFAULT_CHAIN_ID = Networks.FANTOM_TESTNET;
// export const USER_SELECTABLE_NETWORKS = [Networks.FANTOM_TESTNET];
// // fantom
// export const DEFAULT_CHAIN_NAME = "Fantom";
// export const DEFAULT_CHAIN_NETWORK_NAME = "fantom";
// export const DEFAULT_NETWORK = Networks.FANTOM;
// export const DEFAULT_CHAIN_ID = Networks.FANTOM;
// export const USER_SELECTABLE_NETWORKS = [Networks.FANTOM];
// // BNBTEST
// export const DEFAULT_CHAIN_NAME = "BSCtest";
// export const DEFAULT_CHAIN_NETWORK_NAME = "bsctest";
// export const DEFAULT_NETWORK = Networks.BNBTEST;
// export const DEFAULT_CHAIN_ID = Networks.BNBTEST;
// export const USER_SELECTABLE_NETWORKS = [Networks.BNBTEST];

// // GOERLI
// export const DEFAULT_CHAIN_NAME = "GOERLI";
// export const DEFAULT_CHAIN_NETWORK_NAME = "goerli";
// export const DEFAULT_NETWORK = Networks.GOERLI;
// export const DEFAULT_CHAIN_ID = Networks.GOERLI;
// export const USER_SELECTABLE_NETWORKS = [Networks.GOERLI];

// MAINNET
export const DEFAULT_CHAIN_NAME = "MAINNET";
export const DEFAULT_CHAIN_NETWORK_NAME = "mainnet";
export const DEFAULT_NETWORK = Networks.MAINNET;
export const DEFAULT_CHAIN_ID = Networks.MAINNET;
export const USER_SELECTABLE_NETWORKS = [Networks.MAINNET];

interface INativeCurrency {
  name: string;
  symbol: string;
  decimals?: number;
}

interface INetwork {
  chainName: string;
  chainId: number;
  nativeCurrency: INativeCurrency;
  rpcUrls: string[];
  blockExplorerUrls: string[];
  //   image?: SVGImageElement;
  imgURI: string;
  imageAltText?: string;

  uri: () => string;
}

export const NETWORKS: { [key: number]: INetwork } = {
  592: {
    chainName: "Astar ",
    chainId: 592,
    nativeCurrency: {
      name: "ASTR",
      symbol: "ASTR",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.astar.network:8545"],
    blockExplorerUrls: ["https://blockscout.com/astar"],
    uri: () => {
      return "https://rpc.astar.network:8545";
    },
    imgURI: "/img/ASTR.png",
  },
  81: {
    chainName: "Shibuya ",
    chainId: 81,
    nativeCurrency: {
      name: "Shibuya",
      symbol: "SBY",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.shibuya.astar.network:8545"],
    blockExplorerUrls: ["https://blockscout.com/shibuya"],

    uri: () => {
      return "hthttps://rpc.shibuya.astar.network:8545";
    },
    imgURI: "/img/SBY.png",
  },
  336: {
    chainName: "Shiden ",
    chainId: 336,
    nativeCurrency: {
      name: "Shiden",
      symbol: "SDN",
      decimals: 18,
    },
    rpcUrls: [
      "https://rpc.shiden.astar.network:8545",
      "https://shiden.api.onfinality.io/public",
    ],
    blockExplorerUrls: ["https://blockscout.com/shiden"],

    uri: () => {
      return "https://rpc.shiden.astar.network:8545";
    },
    imgURI: "/img/SDN.png",
  },
  4002: {
    chainName: "FantomTest",
    chainId: 4002,
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.testnet.fantom.network"],
    blockExplorerUrls: ["https://testnet.ftmscan.com"],
    uri: () => {
      return "https://rpc.testnet.fantom.network";
    },
    imgURI: "/img/FTM.png",
  },
  250: {
    chainName: "Fantom",
    chainId: 250,
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.ftm.tools/"],
    blockExplorerUrls: ["https://ftmscan.com"],
    uri: () => {
      return "https://rpc.ftm.tools/";
    },
    imgURI: "/img/FTM.png",
  },
  80001: {
    chainName: "Mumbai",
    chainId: 80001,
    nativeCurrency: {
      name: "Mumbai",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: [
      "https://polygon-mumbai.g.alchemy.com/v2/3AUyM1_NoaEnBfvDrQgUxAdLYCiklOYB",
    ],
    // rpcUrls: ["https://matic-testnet-archive-rpc.bwarelabs.com"],
    // rpcUrls: ["https://rpc-mumbai.matic.today"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],

    uri: () => {
      return "https://polygon-mumbai.g.alchemy.com/v2/3AUyM1_NoaEnBfvDrQgUxAdLYCiklOYB";
      // return "https://matic-testnet-archive-rpc.bwarelabs.com";
      // return "https://rpc-mumbai.matic.today";
    },
    imgURI: "/img/matic.png",
  },
  137: {
    chainName: "polygon",
    chainId: 137,
    nativeCurrency: {
      name: "polygon",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com	"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],

    uri: () => {
      return "https://polygon-rpc.com	";
    },
    imgURI: "/img/matic.png",
  },
  97: {
    chainName: "BNBTest",
    chainId: 97,
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    // rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],

    blockExplorerUrls: ["https://testnet.bscscan.com"],
    imgURI: "/img/BNB.png",
    imageAltText: "BNBTest",
    // uri: () => "https://data-seed-prebsc-1-s1.binance.org:8545/",
    uri: () => "https://data-seed-prebsc-1-s1.binance.org:8545/",
  },
  56: {
    chainName: "BNB",
    chainId: 56,
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com"],
    imgURI: "/img/BNB.png",
    imageAltText: "BNB",
    uri: () => "https://bsc-dataseed.binance.org",
  },
  43113: {
    chainName: "Avalanche Fuji Testnet",
    chainId: 43113,
    nativeCurrency: {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://testnet.snowtrace.io/#/"],
    imgURI: "/img/AVAX.png",

    imageAltText: "Avalanche Logo",
    uri: () => "https://api.avax-test.network/ext/bc/C/rpc",
  },
  43114: {
    chainName: "Avalanche",
    chainId: 43114,
    nativeCurrency: {
      name: "Avalanche",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://cchain.explorer.avax.network/"],
    imgURI: "/img/AVAX.png",
    imageAltText: "Avalanche Logo",
    uri: () => "https://api.avax.network/ext/bc/C/rpc",
  },
  // 1: {
  //   chainName: "Ethereum Mainnet",
  //   chainId: 1,
  //   nativeCurrency: {
  //     name: "ETH",
  //     symbol: "ETH",
  //     decimals: 18,
  //   },
  //   rpcUrls: [
  //     "https://eth-mainnet.g.alchemy.com/v2/ReAqABjZ4DRQC-D16WzMrUAts8aXtAPa",
  //   ],
  //   blockExplorerUrls: ["https://etherscan.io/"],
  //   imgURI: "/img/ETH.png",
  //   imageAltText: "Ethereum Logo",
  //   uri: () =>
  //     "https://eth-mainnet.g.alchemy.com/v2/ReAqABjZ4DRQC-D16WzMrUAts8aXtAPa",
  // },

  1: {
    chainName: "Ethereum mainnet",
    chainId: 1,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [
      "https://eth-mainnet.g.alchemy.com/v2/ReAqABjZ4DRQC-D16WzMrUAts8aXtAPa",
    ],
    blockExplorerUrls: ["https://etherscan.io/"],
    imgURI: "/img/ETH.png",
    imageAltText: "Ethereum Logo",
    uri: () =>
      "https://eth-mainnet.g.alchemy.com/v2/ReAqABjZ4DRQC-D16WzMrUAts8aXtAPa",
  },
  5: {
    chainName: "Ethereum Goerli",
    chainId: 5,
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [
      "https://eth-goerli.g.alchemy.com/v2/ZRAKVYkEFJ_5GVrUv3u3DWmgudcR-vYp",
    ],
    blockExplorerUrls: ["https://goerli.etherscan.io/"],
    imgURI: "/img/ETH.png",
    imageAltText: "Ethereum Logo",
    uri: () =>
      "https://eth-goerli.g.alchemy.com/v2/ZRAKVYkEFJ_5GVrUv3u3DWmgudcR-vYp",
  },
};
